<template>
	<div class="ac_tips_overlay" @click="close()">
		<div class="ac_tips">
			<div class="ac_tips_top">
				<div class="ac_tips_img">
					<img :src="item.pic" />
				</div>
				<div class="ac_tips_info">
					<div class="ac_tips_info_status">
						<div class="ac_tips_info_status_point"></div>
						<div class="ac_tips_info_status_name">{{
							item.startStatus == 0
								? "活动尚未开始"
								: item.startStatus == 1
								? "活动正在进行中"
								: "活动已结束"
						}}</div>
					</div>
					<div class="ac_tips_info_name">{{ item.title }}</div>
					<div class="ac_refund_num">
						<span>票数</span>：<span>{{ item.ticket_count }}</span
						><span>{{ item.ticket_name }}</span
						><span>折扣码</span>：{{ item.ticket_code }}
					</div>
					<div class="ac_refund_price">
						<span class="ac_refund_price_text"> 总金额 </span>
						：
						<span class="ac_refund_price_num">
							{{ item.ticket_type == 0 ? "免费" : item.ticket_price + " USD" }}
						</span>
					</div>
				</div>
			</div>
			<div class="ac_refund_header_title">联系方式:</div>
			<div class="ac_refund_header_content">
				<el-input
					placeholder="請務必確認淸楚聯繋方式，我們將會透過此郵箱聨緊您處理後績退費程"
					v-model="email"
					@click.stop="inputClicked"
					@click.native.stop
				>
				</el-input>
			</div>
			<div class="ac_refund_header_title">退費原因:</div>
			<div class="ac_refund_header_content">
				<el-input
					placeholder="填写退费原因"
					v-model="reason"
					@click.stop="inputClicked"
					@click.native.stop
				>
				</el-input>
			</div>
			<div class="ac_tips_bottom">
				<div class="ac_tips_bottom_btn" @click="refundSure()">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		item: {},
	},
	created() {},
	data() {
		return {
			email: "",
			reason: "",
		};
	},
	methods: {
		inputClicked() {
			alert("Input clicked");
		},
		close() {
			this.$emit("close");
		},

		refundSure() {
			this.$emit("refundSure", {
				id: this.item.ticket_id,
				email: this.email,
				reason: this.reason,
				status: 2,
			});
		},
	},
	watch: {},
};
</script>

<style scoped>
.ac_tips_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.ac_tips {
	width: 50%;
	min-width: 900px;
	padding: 20px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
}
</style>

<!-- ac_tips_top -->
<style scoped>
.ac_tips_top {
	display: flex;
}
.ac_tips_img {
	width: 40%;
}
.ac_tips_img img {
	width: 100%;
	aspect-ratio: 16 / 9;
	border-radius: 20px;
	opacity: 1;
	object-fit: cover;
}
.ac_tips_info {
	width: 55%;
	margin-left: 20px;
}
.ac_tips_info_status {
	width: 80px;
	height: 22px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 7px;
	display: flex;
	/* 主要色/主要色 */
	background: rgba(1, 86, 255, 0.1);
	justify-content: space-between;
	align-content: center;
	text-align: center;
	align-items: center;
}
.ac_tips_info_status_point {
	width: 8px;
	height: 8px;
	border-radius: 136px;
	opacity: 1;

	/* 主要色/主要色 */
	background: #0256ff;
}

.ac_tips_info_status_name {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: 400;
	line-height: 10px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_tips_info_name {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	color: #333333;
}

.ac_refund_num {
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 描边色/描边色辅助 */
	color: #aaaaaa;
}

.ac_refund_price {
	margin-top: 20px;
	display: flex;
}
.ac_refund_price_text {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_refund_price_num {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 30px;
	font-weight: 400;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}
.ac_tips_info_line {
	margin-top: 16px;
	width: 163px;
	height: 30px;
	border-radius: 10px;
	/* 333 */
	background: rgba(51, 51, 51, 0.2);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	/* display: flex; */
	align-items: center;
	text-align: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}
</style>

<!-- ac_tips_content -->
<style scoped>
.ac_refund_header_title {
	margin-top: 30px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}
.ac_refund_header_content {
	margin-top: 15px;
}
</style>

<!-- ac_tips_bottom -->
<style scoped>
.ac_tips_bottom {
	margin-top: 30px;
	height: 42px;
	width: 100%;
	text-align: center;
	/* display: flex; */
}

.ac_tips_bottom_btn {
	border-radius: 21px;
	display: inline-block;
	opacity: 1;
	height: 42px;
	width: 140px;
	/* 主要色/主要色 */
	background: #0256ff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
}
</style>

<style></style>
